// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-broker-index-js": () => import("./../../../src/templates/broker-index.js" /* webpackChunkName: "component---src-templates-broker-index-js" */),
  "component---src-templates-broker-page-js": () => import("./../../../src/templates/broker-page.js" /* webpackChunkName: "component---src-templates-broker-page-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-primary-page-js": () => import("./../../../src/templates/primary-page.js" /* webpackChunkName: "component---src-templates-primary-page-js" */),
  "component---src-templates-secondary-page-js": () => import("./../../../src/templates/secondary-page.js" /* webpackChunkName: "component---src-templates-secondary-page-js" */)
}

